<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-4">
              <select v-model="search_data.menu_setting_desc" v-validate="{ required: true }" id="menu_setting_desc" name="menu_setting_desc" class="form-control form-control-sm">
                <option selected disabled :value="''">Select a menu setting desc</option>
                <option value="PRODUCT_REQUISITION_CREATE">PRODUCT_REQUISITION_CREATE</option>
                <option value="DELIVERY_CHALLAN_CREATE">DELIVERY_CHALLAN_CREATE</option>
              </select>
              <div class="invalid-feedback">Menu setting desc is required</div>
            </div>
            <div class="col-md-3">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="getUserMenuSettings()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-md-12">
        <a-button :disabled="selectedIds.length > 0 ? false : true" class="btn btn-success float-right" :loading="onBtnLoading" @click.prevent="onAll"><i class="fa fa-check mr-1" aria-hidden="true"></i>On All</a-button>
        <a-button :disabled="selectedIds.length > 0 ? false : true" class="btn btn-danger float-right mr-2" :loading="offBtnLoading" @click.prevent="offAll"><i class="fa fa-remove mr-1" aria-hidden="true"></i>Off All</a-button>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <a-tooltip placement="top">
            <template slot="title"><span>Add User Menu Setting</span></template>
            <a href="javascript: void(0);" class="btn btn-sm btn-success float-right mb-1" v-b-modal.add-update-user-menu-setting-modal @click="$bvModal.show('add-update-user-menu-setting-modal'), resetForm()">
              <i class="fa fa-plus"/> Add User Menu Setting
            </a>
          </a-tooltip>
          <table class="table table-sm table-bordered" id="user_menu_setting_list">
            <thead>
            <tr>
              <th><input v-if="user_menu_settings.length > 0" type="checkbox" v-model="selectAll" @click="CheckedAll" /></th>
              <th>#</th>
              <th>Status</th>
              <th>User</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(user_menu_setting, index) in user_menu_settings" :key="index">
              <td><input type="checkbox" v-model="selectedIds" :value="user_menu_setting.id" @change="selectOne"></td>
              <td>{{ index + 1 }}</td>
              <td>
                <a-tag color="#87d068"  v-if="user_menu_setting.status == 1">
                  ON
                </a-tag>
                <a-tag color="#f50" v-else>
                  OFF
                </a-tag>
              </td>
              <td>{{ user_menu_setting.user ? user_menu_setting.user.name + ' - ' + user_menu_setting.user.phone_no : '' }}</td>
              <td>
                <a class="btn btn-sm btn-primary mr-2" href="javascript: void(0);" @click.prevent="edit(user_menu_setting.id), $bvModal.show('add-update-user-menu-setting-modal')"><i class="fe fe-edit mr-1"></i>Update</a>
              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="user_menu_settings.length === 0 && !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
        </div>
      </div>
      <add-update-user-menu-setting
        :user_menu_setting="user_menu_setting"
        :btnEdit="btnEdit"
        @getUserMenuSettings="getUserMenuSettings"
        @resetForm="resetForm"
        ref="addUpdateMenuSetting">
      </add-update-user-menu-setting>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'
import addUpdateUserMenuSetting from '@/views/userMenuSetting/modal/addUpdateUserMenuSetting'
import $ from 'jquery'

export default {
  name: 'List',
  components: {
    Empty,
    addUpdateUserMenuSetting,
  },
  data() {
    return {
      hubs: [],
      user_menu_settings: [],
      user_menu_setting: {},
      selectedIds: [],
      selectAll: false,
      onBtnLoading: false,
      offBtnLoading: false,
      loading: false,
      loader: false,
      flag: false,
      btnEdit: false,
      btnLoading: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      search_data: {
        menu_setting_desc: '',
      },
    }
  },
  mounted() {},
  methods: {
    getUserMenuSettings() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.flag = true
          this.loading = true
          this.loader = true
          apiClient.post('api/user-menu-setting/search', this.search_data)
            .then(response => {
              this.loading = false
              this.flag = true
              this.loader = false
              this.user_menu_settings = response.data.user_menu_settings
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
    },
    edit(id) {
      this.user_menu_setting = {}
      this.btnEdit = true
      apiClient.get('api/user-menu-settings/' + id + '/edit').then(response => {
        if (!response.data.error) {
          this.user_menu_setting = response.data.user_menu_setting
        }
      })
    },
    onDelete(Id) {
      if (confirm('Do you really want to delete?')) {
        apiClient.delete('api/user-menu-settings/' + Id).then(response => {
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              $('#user_menu_setting_list').DataTable().destroy()
              this.getUserMenuSettings()
            }
          } else {
            this.$notification.error({
              message: 'Deleted Failed',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    CheckedAll() {
      this.selectedIds = []
      if (!this.selectAll) {
        const userMenuSettings = this.user_menu_settings
        for (const i in userMenuSettings) {
          this.selectedIds.push(userMenuSettings[i].id)
        }
      }
    },
    selectOne() {
      this.selectAll = false
      if (this.selectedIds.length === this.user_menu_settings.length) {
        this.selectAll = true
      }
    },
    onAll() {
      if (this.selectedIds.length > 0) {
        this.onBtnLoading = true
        apiClient.post('api/user-menu-setting/onAll', { userMenuSettingIds: this.selectedIds })
          .then(response => {
            this.onBtnLoading = false
            this.selectAll = false
            this.selectedIds = []
            this.getUserMenuSettings()
            this.$notification.success({
              message: response.data.message,
            })
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        this.$notification.error({
          message: 'Please select any checkbox from table',
        })
      }
    },
    offAll() {
      if (this.selectedIds.length > 0) {
        this.offBtnLoading = true
        apiClient.post('api/user-menu-setting/offAll', { userMenuSettingIds: this.selectedIds })
          .then(response => {
            this.offBtnLoading = false
            this.selectAll = false
            this.selectedIds = []
            this.getUserMenuSettings()
            this.$notification.success({
              message: response.data.message,
            })
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        this.$notification.error({
          message: 'Please select any checkbox from table',
        })
      }
    },
    resetForm() {
      this.btnEdit = false
      this.user_menu_setting = {}
    },
  },
}
</script>

<style scoped>

</style>
